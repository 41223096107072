import Head from "next/head";
import { FC } from "react";
import { SITE_NAME } from "utils/constants";

const PageTitle: FC<{ title: string }> = ({ title }) => {
  const _title = `${title} - ${SITE_NAME}`;

  return (
    <Head>
      <title>{_title}</title>
    </Head>
  );
};

export default PageTitle;
